<template>
  <v-card class="pa-0 pb-10" tile>
    <v-card-title>
      <h4 class="primary--text">
      {{ $t('sidebar.chat.blockedUsers') }}
      </h4>
    </v-card-title>
    <v-row class="pl-6">
      <v-col cols="2">
        <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
      </v-col>
    </v-row>
    <v-data-table
      :search="searchInputValue"
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.role="{item}">
        {{ $store.getters.enums.roles.find(role => role.id === item.role).description }}
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn
          color="primary"
          depressed
          outlined
          @click="unblockUser(item.id)"
        >
          {{ $t('blockedUsersList.unblock') }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Service from '@/services'

export default {
  name: 'BlockedUsersList',
  components: {},
  data () {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: this.$t('blockedUsersList.fullName'),
          value: 'fullName'
        },
        {
          text: this.$t('blockedUsersList.companyId'),
          value: 'company.displayId'
        },
        {
          text: this.$t('blockedUsersList.companyName'),
          value: 'company.name'
        },
        {
          text: this.$t('blockedUsersList.role'),
          value: 'role'
        },
        {
          text: this.$t('blockedUsersList.email'),
          value: 'email'
        },
        {
          text: this.$t('blockedUsersList.phone'),
          value: 'phone'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      searchInputValue: ''
    }
  },
  methods: {
    async getBlockedUsers () {
      this.loading = true
      try {
        const { data } = await Service.get('/Chat/GetBlockedUsers')
        this.items = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async unblockUser (userId) {
      this.loading = true
      try {
        await Service.put('/Chat/UnblockUser', {
          userId: userId
        })
        const { data } = await Service.get('/Chat/GetBlockedUsers')
        this.items = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.getBlockedUsers()
  }
}
</script>
